"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.employees = void 0;
const data_1 = require("./geg/data");
const data_2 = require("./dos/data");
const data_3 = require("./thg/data");
const data_4 = require("./jok/data");
const data_5 = require("./daw/data");
exports.employees = {
    geg: data_1.data,
    dos: data_2.data,
    thg: data_3.data,
    jok: data_4.data,
    daw: data_5.data,
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPost = exports.posts = void 0;
const data_1 = require("./analytics/data");
const data_2 = require("./cms/data");
const data_3 = require("./core-web-vitals/data");
const data_4 = require("./e-commerce/data");
const data_5 = require("./internationalisation/data");
const data_6 = require("./live-data-streaming/data");
const data_7 = require("./pwa/data");
const data_8 = require("./regionalisation/data");
const data_9 = require("./seo/data");
exports.posts = [
    data_1.data,
    data_2.data,
    data_3.data,
    data_4.data,
    data_5.data,
    data_6.data,
    data_7.data,
    data_8.data,
    data_9.data,
];
function getPost(slug) {
    return exports.posts.find((post) => post.slug === slug);
}
exports.getPost = getPost;

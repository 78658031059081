"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Team = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
require("./Team.scss");
const employees_1 = require("../data/employees/employees");
const Employee_1 = require("./Employee");
function Team() {
    return ((0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsxs)("div", { className: "container flex items-center flex-col mb-12", children: [(0, jsx_runtime_1.jsx)("h1", { className: "mt-12 mb-4 text-5xl font-bold", children: "Unser Team" }, void 0), (0, jsx_runtime_1.jsxs)("p", { className: "text-center", children: ["Wir sind ein Team von f\u00FCnf talentierten Software-Entwicklern.", (0, jsx_runtime_1.jsx)("br", {}, void 0), "Unsere Expertise liegt in der Entwicklung von Enterprise Web Applications."] }, void 0)] }, void 0), (0, jsx_runtime_1.jsx)("div", { className: "bg-gray-100", children: (0, jsx_runtime_1.jsxs)("div", { className: "container-xl m-auto mt-3 flex flex-wrap justify-center py-4", children: [(0, jsx_runtime_1.jsx)(Employee_1.Employee, { ...employees_1.employees.geg }, void 0), (0, jsx_runtime_1.jsx)(Employee_1.Employee, { ...employees_1.employees.dos }, void 0), (0, jsx_runtime_1.jsx)(Employee_1.Employee, { ...employees_1.employees.thg }, void 0), (0, jsx_runtime_1.jsx)(Employee_1.Employee, { ...employees_1.employees.jok }, void 0), (0, jsx_runtime_1.jsx)(Employee_1.Employee, { ...employees_1.employees.daw }, void 0)] }, void 0) }, void 0)] }, void 0));
}
exports.Team = Team;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Employee = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_fontawesome_1 = require("@fortawesome/react-fontawesome");
const free_brands_svg_icons_1 = require("@fortawesome/free-brands-svg-icons");
function Employee(model) {
    return ((0, jsx_runtime_1.jsx)("div", { className: "p-3 w-full md:w-1/2 lg:w-1/3 ", children: (0, jsx_runtime_1.jsxs)("div", { className: "transition-shadow duration-400 hover:shadow-lg bg-white hover:scale-105  h-full", children: [(0, jsx_runtime_1.jsxs)("picture", { children: [(0, jsx_runtime_1.jsx)("source", { srcSet: model.image.webp }, void 0), (0, jsx_runtime_1.jsx)("img", { src: model.image.jpg }, void 0)] }, void 0), (0, jsx_runtime_1.jsxs)("div", { className: "mt-2 p-4", children: [(0, jsx_runtime_1.jsx)("h2", { className: "text-lg", children: model.name }, void 0), (0, jsx_runtime_1.jsx)("p", { className: "font-bold", children: model.role }, void 0), (0, jsx_runtime_1.jsx)("p", { className: "mt-2", children: model.text }, void 0), (0, jsx_runtime_1.jsxs)("p", { className: "mt-2", children: [(0, jsx_runtime_1.jsx)("b", { children: "Technologien" }, void 0), ": ", model.technologies.join(" | ")] }, void 0), (0, jsx_runtime_1.jsxs)("p", { className: "mt-2", children: [model.github && ((0, jsx_runtime_1.jsxs)("span", { children: [(0, jsx_runtime_1.jsxs)("a", { href: `https://github.com/${model.github}`, children: [(0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: free_brands_svg_icons_1.faGithub, className: "fa" }, void 0), "visit Github Profile"] }, void 0), (0, jsx_runtime_1.jsx)("br", {}, void 0)] }, void 0)), model.stackoverflow && ((0, jsx_runtime_1.jsxs)("span", { children: [(0, jsx_runtime_1.jsxs)("a", { href: `https://stackoverflow.com/users/${model.stackoverflow}`, children: [(0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: free_brands_svg_icons_1.faStackOverflow, className: "fa" }, void 0), "visit StackOverflow Profile"] }, void 0), (0, jsx_runtime_1.jsx)("br", {}, void 0)] }, void 0))] }, void 0)] }, void 0)] }, void 0) }, void 0));
}
exports.Employee = Employee;
